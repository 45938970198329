<template>
    <div class="partner_selector">
        <el-select v-model="selectedId" @change="setPartnerId" size="mini" placeholder="파트너 선택">
            <el-option
                    :key="0"
                    label="전체"
                    :value="null">
            </el-option>
            <el-option
                    v-for="item in partnerList"
                    :key="item.id"
                    :label="item.nickname"
                    :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {manager} from "../../common/administrator/managerMixin";
    import {getPartnerList} from "../../network/manager/partnerRequest";

    export default {
        name: "PartnerSelectorComp",
        mixins: [manager],
        data() {
            return {
                selectedId:null,
                partnerList: [],
                partner: {
                    'generalAgentId': 0
                },
                pageNum: 1,
                pageSize: 1000,
            }
        },
        methods: {
            setPartnerId(){
                this.$emit('setPartnerId',this.selectedId)
            }
        },
        computed: {},
        created() {
            getPartnerList(this.partner, this.pageNum, this.pageSize).then(res => {
                let list = res.data.data;
                list.map(partner => {
                    partner.nickname = '***' + partner.nickname + '*** [부본사]'
                    this.partnerList.push(partner);
                    let agentList = partner.agentList;
                    agentList.map(agent => {
                        agent.nickname = '   ' + agent.nickname + '(총판)'
                        this.partnerList.push(agent)
                    });
                });
            })
        },
        watch: {},
    }
</script>

<style scoped>

</style>